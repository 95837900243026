.App{
    width: 100vw;
    height: 100vh;
    position:'relative';
    overflow: hidden;
}

@media screen and (max-width:575.98px) {
    .App{
        display: flex;
        flex-flow: nowrap column;
    }
}